import { twMerge } from "tailwind-merge";
import { motion } from "motion/react";
import { Check, Copy } from "lucide-react";
import { useMemo, useRef, useState } from "react";

export const PreCopyButton = () => {
  const selfRef = useRef<null | HTMLButtonElement>(null);
  const [isCopying, setIsCopying] = useState(false);
  const [copied, setCopied] = useState(false);

  const state = useMemo(() => {
    if (isCopying) return "copying";
    if (copied) return "copied";
    return "idle";
  }, [isCopying, copied]);

  const copy = async () => {
    if (!selfRef.current) return;
    const pre = selfRef.current.closest(".ide")?.querySelector("pre");
    if (!pre) return;
    const copiedPre = pre.cloneNode(true) as HTMLElement;
    // remove all .gutter elements
    copiedPre.querySelectorAll(".gutter").forEach((el) => el.remove());
    // click all summary elements
    copiedPre.querySelectorAll("summary").forEach((el) => el.click());
    // remove all summaries
    copiedPre.querySelectorAll("summary").forEach((el) => el.remove());
    // change details to div
    copiedPre.querySelectorAll("details").forEach((el) => {
      const div = document.createElement("div");
      div.innerHTML = el.innerHTML;
      el.replaceWith(div);
    });

    Object.assign(copiedPre.style, {
      opacity: "0",
      pointerEvents: "none",
      position: "absolute",
      overflow: "hidden",
      left: "0",
      top: "0",
      width: "20px",
      height: "20px",
      webkitUserSelect: "auto",
      userSelect: "all",
    });
    document.body.appendChild(copiedPre);

    const range = document.createRange();
    range.selectNode(copiedPre);
    const selection = window.getSelection();
    if (!selection) return;
    selection.removeAllRanges();
    selection.addRange(range);
    setIsCopying(true);

    let ok = false;
    try {
      ok = document.execCommand("copy");
      setIsCopying(false);
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    } catch (err) {
      setCopied(false);
    }

    document.body.removeChild(copiedPre);
  };

  return (
    <button
      ref={selfRef}
      className="border-white-opaque absolute right-4 top-4 z-10 flex items-center justify-center rounded-xl border bg-[rgba(255,255,255,.1)] p-2 shadow-lg transition-colors duration-300 hover:bg-[rgba(255,255,255,.2)] focus-visible:bg-[rgba(255,255,255,.2)]"
      onClick={() => copy()}
    >
      <span className="sr-only">Copy code</span>
      {(state === "idle" || state == "copying") && (
        <Copy
          className={twMerge("h-4 w-4", state === "copying" && "animate-pulse")}
        />
      )}
      {state === "copied" && (
        <motion.span
          initial={{ scale: 0.4 }}
          animate={{ scale: 1 }}
          transition={{
            type: "spring",
            damping: 10,
            stiffness: 100,
          }}
        >
          <Check className="h-4 w-4" />
        </motion.span>
      )}
    </button>
  );
};
